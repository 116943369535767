.toolbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    height: 72px;
    /* border-bottom: 0.8px solid #333333; */
    box-shadow: 0px 0px 5.5px black;
    z-index: 101;
}

.toolbar-nav {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 1.45rem;
}

.toolbar-nav a {
    margin-right: 5px;
}

.left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.toolbar-logo {
    margin-left: 0.9rem;
    align-self: center;
}

.toolbar-logo a {
    color: black;
    font-size: 1.85rem;
    text-decoration: none;
}

.toolbar-logo a:hover {
    color: #0c94a0;
}

.toolbar-nav-items {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toolbar-nav-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar-nav-items li {
    padding: 0 0.5rem;
}

.toolbar-nav-items a {
    color: black;
    text-decoration: none;
}

.toolbar-nav-items a:hover,
toolbar-nav-items a:active {
    color: #0c94a0;
}

.search-bar {
    border-radius: 5px;
    margin-right: 5px;
    height: 17.5px;
    border: 1px solid black;
}

.search-li {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: -3.5px;
}

.search-icon {
    cursor: pointer;
}

.user-icon-container {
    background-color: black;
    border-radius: 50%;
}

.user-icon {
    cursor: pointer;
}

.profile-detail-container {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
}

.profile-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    background-color: white;
    padding: 10px;
    border: 1px solid black;
    margin-right: 5px;
    border-radius: 10px;
}

.profile-detail * {
    padding: 5px;
}

.about-link {
    color: black;
    margin-left: 10px;
}

.about-link:hover {
    color: #0c94a0;
}

.about-link,.sign-out-link {
    text-decoration: none;
}

.sign-out-link {
    color: black;
}

.sign-out-link:hover {
    color: #0c94a0;
}

.toolbar-logo a {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toolbar-logo-icon {
    height: 40px;
    width: 40px;
    margin-right: 5px;
}

@media(max-width: 1076px) {
    .toolbar-logo a {
        font-size: 1.45rem;
    }

    .toolbar-nav {
        font-size: 1.25rem;
    }
}

@media(max-width: 960px) {
    .toolbar-logo a {
        font-size: 1.25rem;
    }

    .toolbar-nav {
        font-size: 1.05rem;
    }
}

@media(max-width: 796px) {
    .toolbar-logo a {
        font-size: 1.05rem;
    }

    .toolbar-nav {
        font-size: 0.85rem;
    }
}

@media(max-width: 660px) {
    .logo-text-toolbar {
        display: none;
    }

    .toolbar-nav {
        font-size: 0.85rem;
    }

    .toolbar-logo-icon {
        margin-right: 0px;
    }
}

.mobile-menu-btn {
    display: none;
}

@media (max-width: 492px) {
    .mobile-menu-btn {
        display: inline;
        font-size: 2.5rem;
        cursor: pointer;
        color: #623a29;
    }

    .logo-text-toolbar {
        display: inline;
        font-size: 1.2rem;
    }

    .search-li {
        display: none;
    }

    .toolbar-nav-items {
        display: none;
    }

    .left-side-not-subscribed {
        display: none;
    }

    .left-side > a {
        display: none;
    }

    .toggle-btn {
        color: #623a29;
    }
}