/* Style sheet for daily dinners menu 

pan icon source: https://www.pngitem.com/middle/mihiTx_pan-pan-icon-png-free-transparent-png/

	- Sunday: Orange (avo)
	- Monday: Harvest Gold (dark aqua)
	- Tuesday: Chartruce (brown)
	- Wednesday: Avocado Green (gold)
	- Thursday: Light Aqua (char)
	- Friday: Dark Aqua (orange)
	- Saturday: Brown (light aqua)
*/

.orange-bg {
	background-color: #ff8c11;
	/* rgb: 255, 140, 17 */
}

.gold-bg {
	background-color: #d4bd19;
	/* rgb: 212, 189, 25 */
}

.chartreuse-bg {
	background-color: #c4d10d;
	/* rgb: 196, 209, 13 */
}

.avo-green-bg {
	background-color: #74842c;
	/* rgb: 116, 132, 44 */
}

.light-aqua-bg {
	background-color: #29cccf;
	/* rgb: 41, 204, 207 */
}

.dark-aqua-bg {
	background-color: #0c94a0;
	/* rgb: 12, 148, 160 */
}

.brown-bg {
	background-color: #623a29;
	/* rgb: 98, 58, 41 */
}

body {
	font-weight: bold;
	font-family:'Century Gothic', 'Helvetica'
}

.icon {
	position: relative;
	top: -5px;
}

#day-text {
	align-self: flex-end;
	margin-right: 10px;
	margin-top: 5px;
}

.ingredient {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	color: white;
	font-weight: bold;
	margin-bottom: 10px;
	margin-right: 5px;
}

.ingredient-text {
	margin-left: 5px;
	margin-top: 5px;
	margin-right: 5px;
	color: #74842c;
	text-align: left;
}

.ingredient img {
	margin-left: 5px;
}

.content-wrapper {
	height: 1015px;
	width: 768px;
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 999;
	background-color: white;
	left: 25%;
	font-weight: normal;
	border: 1px solid white;
	box-shadow: 2px 2px 6px white;
}

@media(max-width: 492px) {
	.content-wrapper {
		width: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		height: 2500px;
	}
}

#header {
	height: 196px;
	display: flex;
	flex-direction: row;
	color: #ff8c11;
	width: 768px;
	/* text-shadow: 0.32px 0.32px 0.64px black; */
	font-weight: bold;
}

@media(max-width: 492px) {
	#header {
		min-height: 416px;
		display: flex;
		flex-direction: column-reverse;
		width: 100vw;
		margin-top: 36px;
	}
}

@media (max-width: 324px) {
	#header {
		min-height: 488px;
	}
}

.lower-content {
	display: flex;
	flex-direction: row;
	color: #000;
}

@media(max-width: 492px) {
	.lower-content {
		flex-direction: column;
	}
}

.recipe-container {
	width: 192px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	margin-left: 60px;
	font-size: 28;
	font-weight: normal;
	margin-bottom: 12px;
	/* text-shadow: 0.5px 0.5px 1px black; */
	font-weight: bold;
	font-size: 16pt;
}

@media(max-width: 492px) {
	.title {
		margin-left: 24px;
		margin-top: 12px;
	}
}

.time-container {
	margin-top: 15px;
	margin-left: 72px;
	max-width: 360px;
	font-weight: normal;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* font-weight: bold; */
}

@media(max-width: 492px) {
	.time-container {
		max-width: 100%;
		margin-left: 24px;
	}
}

#time-intervals {
	margin-left: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

#equipment-text {
	margin-left: 5px;
}

.equipment-container {
	margin-left: 72px;
	max-width: 480px;
	margin-bottom: 60px;
	font-weight: normal;
	flex-direction: row;
	display: flex;
	align-items: center;
	font-style: italic;
}

@media(max-width: 492px) {
	.equipment-container {
		width: 90%;
		margin-left: 24px;
		margin-bottom: 24px;
		flex: 1;
	}

	.time-container {
		flex: 1;
	}

	#equipment-text {
		width: 100%;
	}
}

#pan {
	height: 40px;
	width: 40px;
}

.clock {
	height: 40px;
	width: 40px;
}

.ingredients-list {
	margin-top: 10px;
	width: 192px;
	margin-left: 0px;
}

.ingredients-title {
	display: flex;
	justify-content: center;
	flex-direction: row;
}

#pic {
	width: 192px;
	height: 192px;
	margin-bottom: 10px;
/*	border: 1px solid black;
*/}

#header-words {
	display: flex;
	flex-direction: column;
	width: 576px;
}

@media(max-width: 492px) {
	#header > img {
		align-self: center;
	}

	#header-words {
		width: 100%;
	}
}

.directions-container {
/*	border-top: 2px solid black;
*/
	margin-left: 24px;
	margin-right: 24px;
	margin-top: 5px;
}

.directions-container * {
	margin-top: 5px;
	margin-left: 5px;
}

.time-text { 
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-bottom: 7.5px;
	font-style: italic;
}

.starburst-ingrident {
	height: 25px;
	width: 25px;
}

@media(max-width: 492px) {
	.ingredients-container {
		width: 90%;
		left: 5%;
		position: relative;
	}

	.ingredients-list {
		width: 100%;
	}
}

@media (min-width: 492px) {
	.ingredients-container {
		height: 815px;
	}
}