.faq p {
    padding: 20px;
    margin-left: 35px;
    margin-right: 35px;
    font-weight: normal;
    /* background-color: #d4bd19; */
    
    border-radius: 15px;
    box-shadow: 0px 0px 4.5px #623a29;
    /* color: #623a29; */
}

.faq {
}

.faq h1 {
    text-align: center;
}

.faq h3 {
    text-align: center;
    color: #623a29;
}