.toggle-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    box-sizing: border;
    box-shadow: none;
    margin-right: 5px;
    color: black;
    font-size: 1.45rem;
}

.toggle-btn:hover {
    color: #0c94a0;
}

.toggle-btn:hover {
    box-shadow: none;
}

.toggle-btn:focus {
    outline: none;
}

.toggle-btn-line {
    width: 27px;
    height: 2px;
    background-color: black;
}

.drawer-toggle-container {
    display: flex;
    flex-direction: row;
    color: #c4d10d
}

.drawer-toggle-container:hover > .menu-btn {
    color: #0c94a0;
}

.toggle-btn:hover > .toggle-btn-line {
    background-color: #0c94a0;
}

.menu-btn {
    margin-left: 35px;
    margin-right: 35px;
    color: black;
    box-shadow: none;
}

.menu-btn:hover {
    box-shadow: none;
}

@media (max-width: 492px) {
    .menu-btn {
        display: none;
    }

    .toggle-btn {
        color: #623a29;
    }
}

.toggle-btn-hidden {
    display: none;
}