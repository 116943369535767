.create-blog-text-area {
    width:450px;
}

.blog-creation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submit-blog {
    background-color: #74842c;
    margin-top: 25px;
}

#date-input {
    margin-top: 25px;
    border: 1px solid black;
}

.date-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 450px;
}

.date-label {
    margin-top: 15px;
    width: 125px;
}

.file-input {
    width: 350px;
}