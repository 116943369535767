.side-drawer {
    height: 100%;
    /* background: #b0cf9f; */
    background-color: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 65%;
    max-width: 350px;
    z-index: 102;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    color: black;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer .week-options {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.side-drawer .week-container {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: larger;
    margin-bottom: 10px;
}

.week-title {
    margin-bottom: 10px;
}

.side-drawer div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.side-drawer a {
    text-decoration: none;
    font-size: 1.35rem;
    padding: 10px;
    border: 1px solid black;
    height: 22.5px;
    width: 22.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    margin-right: 1px;
}

.disabled-week {
    color: #bf5d28;
    text-decoration: none;
    font-size: 1.35rem;
    background-color: grey;
    padding: 10px;
    border: 1px solid white;
    cursor: pointer;
}

.disabled-week:hover,
.disabled-week:active {
    color: black;
}

.complimentary-recipes {
    align-self: flex-start;
}

#purchase {
    background: none;
    border: none;
    color: #d4bd19;
    font-style: none;
    font-size: larger;
    text-shadow: 0.5px 0.5px 2px #d4bd19;
    font-weight: bolder;
}

#purchase:hover {
    color: black;
    text-shadow: 0.5px 0.5px 1.5px #d4bd19;
}

@media (max-width: 492px) {
    .side-drawer.open {
        width: 90%;
    }
}

.non-member-text {
    font-style: italic;
    padding: 20px;
    font-weight: normal;
}

.sunday-side-drawer {
    background-color: #ff8c11;
    color: white;
}
.sunday-side-drawer:hover,.sunday-side-drawer:active {
    color: black;
}

.monday-side-drawer {
    background-color: #d4bd19;
    color: #623a29;
}
.monday-side-drawer:hover,.monday-side-drawer:active {
    color: white;
}

.tuesday-side-drawer {
    background-color: #c4d10d;
    color: #623a29;
}
.tuesday-side-drawer:hover,.tuesday-side-drawer:active {
    color: white;
}

.wednesday-side-drawer {
    background-color: #74842c;
    /* color: #d4bd19; */
    color: white;
}
.wednesday-side-drawer:hover,.wednesday-side-drawer:active {
    color: black;
}

.thursday-side-drawer {
    background-color: #29cccf;
    /* color: white; */
    color: #623a29;
}
.thursday-side-drawer:hover,.thursday-side-drawer:active {
    color: white;
}

.friday-side-drawer {
    background-color: #0c94a0;
    color: white;
}
.friday-side-drawer:hover,.friday-side-drawer:active {
    color: black;
}

.saturday-side-drawer {
    background-color: #623a29;
    color: white;
}
.saturday-side-drawer:hover,.saturday-side-drawer:active {
    color: black;
}