.blog-title-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: x-large;
    margin: 10px;
}

.blog-title-form-container label {
    margin-right: 10px;
}

.blog-title-input {
    padding: 5px;
    font-size: x-large;
    margin: 5px;
    box-shadow: 0px 0px 1px black;
    border-radius: 10px;
}

.blog-input-container {
    width: 500;
    height: 300;
    font-weight: normal;
}

.blog-input-submit {
    background-color: #ff8c11;
    margin: 10px;
}