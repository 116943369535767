.modal-container {
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    border: 2px solid #0c94a0;
    background-color: #eeeeee;
}

.modal-header {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    width: 100%;
    height: 5%;
}

.modal-close {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 15%;
}

.modal-body {
    padding: 15px;
    height: 90%;
}

.modal-footer {
    height: 5%;
}

.modal-submit {
    background-color: #ff8c11;
    margin: 10px;
}