.close-button {
    display: none;
}

.close-btn {
    background-color: red;
    color: white;
    position: absolute;
    top: 73px;
    height: 20px;
    width: 20px;
    border: 1px solid black;
    font-size: large;
    font-weight: bold;
    z-index: 3000;
    left: 38.25%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.checkout-close-btn {
    background-color: red;
    color: white;
    height: 25px;
    width: 25px;
    border: 1px solid black;
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media(max-width: 492px) {
    .close-btn {
        top: 1px;
        left: 1px;
        height: 30px;
        width: 30px;
    }
}

.close-btn:hover {
    cursor: pointer;
}