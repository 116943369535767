.recipe-link {
    text-decoration: none;
    font-size: 20pt;
    margin-top: 10px;
    text-shadow: 0.75px 0.75px rgb(209, 209, 86);
    cursor: pointer;
    text-align: left;
}

.recipe-link:hover {
    color: #555555;
}

.recipes-wrapper {
    padding-bottom: 20px;
}

.recipe-header {
    color: white;
    text-shadow: 1.25px 1.25px rgb(209, 209, 86);
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 5%;
}

.recipe-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 7.5px;
}

.starburst {
    position: relative;
    top: 5px;
}