.title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    align-content: flex-start;
    flex: 1;
}

.starburst {
    height: 40px;
    width: 40px;
    margin-right: 5px;
}

.meal-pic {
    width: 550px;
    height: 550px;
    opacity: 1;
    border: 1px solid white;
    box-shadow: 0px 0px 0px 1.5px black;
    margin-top: 50px;
    margin-right: 5%;
}

.meal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 73px;
}

@media(min-width: 492px) {
    .meal-container {
        height: 965px;
    }
}

.specific-meal-container {
    display: flex;
    flex-direction: row;
}

.meal-row {
    display: flex;
    flex-direction: row;
}

.meal-details {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
}

.meal-id {
    padding-left: 5%;
    /* font-size: 32pt; */
    /* text-shadow: 1px 1px #444444; */
    background-color: white;
    width: 100%;
    align-self: flex-start;
    margin: 0px;
    padding: 20px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
}
.Sunday .meal-id {
    color: #ff8c11;
}
.Monday .meal-id {
    color: #d4bd19;
}
.Tuesday .meal-id {
    color: #c4d10d;
}
.Wednesday .meal-id {
    color: #74842c;
}
.Thursday .meal-id {
    color: #29cccf;
}
.Friday .meal-id {
    color: #0c94a0;
}
.Saturday .meal-id {
    color: #623a29;
}

.title-elements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10%;
}

.description {
    padding-right: 10%;
    padding-left: 10%;
    font-size: 16pt;
    align-self: center;
    text-align: left;
    font-weight: normal;
}

.Sunday  > .meal-id {
    background-color: #ff8c11;
    color: white;
}

.Monday  > .meal-id {
    background-color: #d4bd19;
    color: #623a29;
}

.Tuesday > .meal-id {
    background-color: #c4d10d;
    color: #623a29;
}

.Wednesday  > .meal-id {
    background-color: #74842c;
    color: white;
}

.Thursday  > .meal-id {
    background-color: #29cccf;
    color: white;
}

.Friday  > .meal-id {
    background-color: #0c94a0;
    color: white;
}

.Saturday  > .meal-id {
    background-color: #623a29;
    color: white;
}

@media (max-width: 972px) {
    .meal-pic {
        width: 400px;
        height: 400px;
        flex: 1;
    }
}

@media (max-width: 768px) {
    .specific-meal-container {
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media (max-width: 492px) {
    .meal-details {
        flex: 1;
    }

    .title-row {
        flex: 1;
    }

    .title-elements {
        flex: 1;
    }

    .description {
        flex: 1;
    }

    .meal-pic {
        height: 275px;
        width: 275px;
        max-width: 95%;
    }

    .specific-meal-container {
        flex: 1;
    }
}
