.try-it-free-text-explanation {
    /* background-color: #eeeeee; */
    padding: 10px;
    font-weight: normal;
    /* box-shadow: 0px 0px 3px black; */
    border-radius: 15px;
}

.try-it-free-days {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 25px;
}

.try-it-free-day {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    text-decoration: none;
    color: #623a29;
    box-shadow: 0px 0px 3px black;
}

@media(max-width: 676px) {
    .try-it-free-day {
        width: 120px;
        height: 120px;
        font-size: 1.1rem;
    }
}

@media(max-width: 544px) {
    .try-it-free-days{
        flex-direction: column;
        height: 750px;
        margin-bottom: 25px;
    }

    .try-it-free-day {
        width: 225px;
        height: 225px;
        font-size: 1.65rem;
    }
}

.try-it-free-Sunday {
    background-color: #ff8c11;
}

.try-it-free-Monday {
    background-color: #d4bd19;
}

.try-it-free-Tuesday {
	background-color: #c4d10d;
}