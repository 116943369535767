.mobile-menu-container {
    position: fixed;
    top: 73px;
    z-index: 999;
    background-color: white;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    padding-top: 15px;
    width: 100%;
    border-bottom: 1px solid black;
}

.mobile-menu-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mobile-menu-link {
    color: black;
    margin-bottom: 10px;
}

.mobile-menu-btn:hover {
    color: #0c94a0;
}

.mobile-menu-right-side {
    margin-right: 30px;
}

.search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 210px;
    margin-left: 10px;
}

.mobile-menu-account-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.mobile-menu-account-detail > span {
    margin-bottom: 5px;
    color: #686868;
    font-style: italic;
}