.signin-screen {
    position: relative;
    top: 58px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
}

.login-screen {
    position: relative;
    top: 58px;
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#root,.App {
    width: 100%;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
	background-color: #29cccf;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 1.5px 1.5px 4.5px black;
}

.sign-in-fields {
    margin-top: 25px;
}

.demo-container {
    height: 275px;
}

.demo-qualifier {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.demo-text {
    color: white;
    text-shadow: 1px 1px 1px black;
}

.sign-up-container {
    height: 250px;
}

.sign-in-button {
    margin-bottom: 20px;
}

.login-container {

}

.sign-in-explanation {
    width: 21%;
    padding: 10px;
    font-weight: normal;
}

@media (max-width: 1108px) {
    .sign-in-container {
        left: 35%;
        width: 30%;
    }

    .sign-in-explanation {
        width: 28%;
        left: 36%;
    }
}

@media (max-width: 912px) {
    .sign-in-container {
        left: 30%;
        width: 40%;
    }

    .sign-in-explanation {
        width: 38%;
        left: 32.5%;
    }
}

@media (max-width: 492px) {
    .sign-in-container {
        width: 60%;
        left: 20%;
    }

    .sign-in-explanation {
        width: 58%;
        left: 21%;
    }
}

@media (max-width: 324px) {
    .sign-in-container {
        width: 75%;
        left: 12.5%;
    }

    .sign-in-explanation {
        width: 70%;
        left: 15%;
    }
}

.sign-in-header {
}

.select-age-label {
    margin-right: 15px;
}

.select-age {
    background-color: #0c94a0;
    color: white;
    width: 100px;
    margin-bottom: 25px;
}

.sign-in-input {
	background-color: #0c94a0;
    margin-bottom: 25px;
    height: 30px;
    border-radius: 25px;
    outline: none;
    color: white;
    box-shadow: 1px 1px 3px black;
}

.forgot-email-input {
	background-color: #dddddd;
    margin-bottom: 25px;
    height: 30px;
    border-radius: 25px;
    outline: none;
    color: #0c94a0;
    box-shadow: 1px 1px 3px black;
    width: 400px;
}

.sign-in-input:active {
    border: none;
    outline: none;
}

.sign-in-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  
  .sign-in-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }
  
  .sign-in-input::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }

.sign-in-button {
    background-color: white;
    height: 45px;
    width: 70px;
    border-radius: 20px;
    color: #0c94a0;
    font-weight: bold;
    text-shadow: 0.25px 0.25px 0.75px black;
    cursor: pointer;
    outline: none;
    box-shadow: 1px 1px 3px black;
    padding: 5px;
}

.outside-sign-in-button {
    background-color: #0c94a0;
    height: 45px;
    width: 225px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    text-shadow: 0.25px 0.25px 0.75px black;
    cursor: pointer;
    outline: none;
    box-shadow: 1px 1px 3px black;
    padding: 5px;
}

.sign-in-button:hover, .outside-sign-in-button:hover {
    box-shadow: 0.5px 0.5px 2px #333333;
}

.sign-up-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin-left: 40%;
}

.secondary-btn {
    background-color: #74842c;
}

.error-zone {
    margin-top: 25px;
    margin-bottom: 25px;
}

.login-purchase-link {
    text-decoration: none;
    font-style: italic;
    font-weight: bold;
    color: black;
}