.newsletter-explanation {
    /* background-color: #eeeeee; */
    padding: 10px;
    font-weight: normal;
    /* box-shadow: 0px 0px 3px black; */
    border-radius: 15px;
    font-size: larger;
}

.newsletter-input {
    border: 1px solid black;
    width: 275px;
}

.newsletter-submit-btn {
    color: white;
    background-color: #74842c;
    border-radius: 10px;
}

.newsletter-message {
    margin-top: 10px;
    font-weight: normal;
    font-style: italic;
}

.Collapsible__trigger {
    background-color: #74842c;
    color: white;
    padding: 15px;
    align-self: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.Collapsible {
    align-self: center;
}

.Collapsible__contentInner {
    border: 1px solid black;
    margin-bottom: 25px;
    margin-top: 25px;
}