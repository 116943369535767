.about-wrapper {
    text-align: center;
    font-weight: normal;
    padding-top: 83px;
}

.intro {
    width: 90%;
    margin-left: 5%;
}

.intro p {
    text-align: left;
}

.top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top-paragraphs {
    width: 65%;
}

.cutaway {
    width: 40%;
    margin-left: 3%;
    background-color: #cccccc;
    border-radius: 15%;
}

.subheader-about {
    color: black;
    /* text-shadow: 0.5px 0.5px 1.5px white; */
    font-style: italic;
}

.profile-pic {
    height: 150px;
    width: 150px;
    border-radius: 75px;
}

.cutaway-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 15px;
}

.cutaway-profile-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.name {
    color: white;
    /* text-shadow: 1px 1px 3px black; */
    margin-bottom: 0px;  
}

.bio {
    font-weight: normal;
    padding-right: 10px;
    padding-left: 10px;
}

.about-section {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
}

.about-inner p {
    font-size: larger;

}

.about-section div {
    padding: 20px;
}

.welcome-photo {
    height: 446.69;
    width: 400px;
    margin-right: 100px;
}

.welcome-text {
    padding: 50px;
}

.target-foods-section {
    display: flex;
    flex-direction: row;
}

.target-foods-photo {
    width: 375px;
    height: 375px;
    box-shadow: 0px 0px 2px black;
    margin: 20px;
}

.david-photo {
    width: 264.22px;
    height: 400px;
}

@media (max-width: 1012px) {
    .child-section {
        flex-direction: column;
        align-items: center;
    }

    .top-paragraphs {
        width: 60%;
    }
    .cutaway {
        width: 45%;
        border-radius: 10%;
        position: relative;
        z-index: -1;
        top: -75px;
    }
}

@media (max-width: 824px) {
    .top-row {
        flex-direction: column-reverse;
    }
    .top-paragraphs {
        width: 100%;
    }
    .cutaway {
        position: static;
        width: 75%;
    }
}

@media (max-width: 615px) {
    .cutaway {
        width: 100%;
    }

    .about-section {
        flex-direction: column;
        align-items: center;
    }

    .welcome-photo {
        margin-right: 0px;
        height: 362.94px;
        width: 325px;
    }

    .welcome-text {
        padding: 0px;
    }

    .target-foods-section {
        flex-direction: column-reverse;
    }

    .target-foods-photo {
        width: 325px;
        height: 325px;
    }
}

@media (max-width: 492px) {
    .cutaway-profile {
        flex-direction: column;
    }
}