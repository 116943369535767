.checkout-area {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 15px;
  font-weight: normal;
}

.checkout-text {
  padding: 5px;
  overflow-x: visible;
}

.sub-display {
  /* border: 0.5px solid black;
  box-shadow: 0px 0px 2px black; */
  padding: 5px;
  height: 212.75px;
  width: 300px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.subscribe-page .header {
  text-align: center;
  /* position: relative;
  top: 20px; */
}

.get-recipe-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin-left: 5%;
}

.purchase-option {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-display { 
  height: 300px;
  width: 300px;
  cursor: pointer;
  margin-bottom: 10px;
}

.vip-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.purchase-button {
  background-color: #0c94a0;
  width: 75%;
}

.purchase-detail {
  font-weight: normal;
  font-style: italic;
  margin-top: 0px;
}

@media (max-width: 976px) {
  .purchase-option {
      width: 40%;
      margin-bottom: 72px;
  }

  .get-recipe-options {
    margin-bottom: 192px;
  }
}

@media (max-width: 492px) {
  .get-recipe-options {
      flex-direction: column;
      align-items: center;
  }

  .purchase-option {
      margin-bottom: 48px;
  }

  .get-recipe-options {
    margin-bottom: 192px;
  }
}

#root {
    display: flex;
    align-items: center;
  }

  #payment-form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #payment-form-container {
    width: 350px;
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    #payment-form-container {
      width: 450px;
    }
  }

  @media (min-width: 592px) {
    #payment-form-container {
      width: 400px;
    }
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  
  form {
    width: 30vw;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }