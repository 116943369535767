.background-stripes {
    height: 475px;
    width: 332.5px;
    border: 1px solid black;
}

.home-content {
    display: flex;
    flex-direction: column;
    font-weight: normal;
}

.home-content .header {
    color: black;
}

.home-content .subheader {
    color: black;
    font-weight: 50;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home-header {
    text-align: center;
    font-weight: normal;
}

.book-area-home {
    margin-left: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.book-area-home > p {
    margin: 10px;
    font-size: 1.35rem;
    font-style: italic;
}

.about-area-home {
    margin-right: 100px;
    margin-left: 100px;
    font-size: 1.1rem;
}

.home-header,.home-subheader {
    align-self: center;
    text-align: center;
}

.home-buy-btn {
    background-color: #d4bd19;
    margin: 10px;
}

.home-buy-btn > a {
    color: black;
    text-decoration: none;
}

@media(max-width: 1032px) {
    .background-stripes {
        width: 297.5px;
        height: 425px;
        align-self: center;
    }
    .about-area-home {
        margin-left: 25px;
        margin-right: 25px;
    }
}

@media(max-width: 776px) {
    .row {
        flex-direction: column;
        align-items: center;
    }

    .book-area-home {
        margin-right: 50px;
        width: 390px;
    }
}

@media(max-width: 492px) {
    .background-stripes {
        width: 273px;
        align-self: center;
        height: 390px;
    }

    .book-area-home {
        width: 300px;
    }
}