.App {
	font-family:'Century Gothic', 'Helvetica'
}

.wrapper {
  margin-top: 84px;
}

.extended-wrapper {
  margin-top: 104px;
}

.header {
  /* color: #ff8c11; */
  /* text-shadow: 1px 1px #0c94a0; */
}
.subheader {
  /* color: #ff8c11; */
  /* text-shadow: 0.5px 0.5px #0c94a0; */
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  margin-top: 84px;
}

@media(max-width: 492px) {
  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.meal-container {
  background-color: white;
}

.search-results {
  margin-top: 73px;
}

.thank-you-words {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
}

.thank-you-words-header {
  text-align: center;
}

  /* Buttons and links */
  .btn {
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.2);
    outline-color: none;
  }
  .btn:hover {
    filter: contrast(115%);
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.6);
  }
  .btn:disabled {
    opacity: 0.5;
    cursor: default;
  }